<template>
  <div class="text-start" style="padding: 0.4rem">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <form>
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <p>{{ $store.getters.companyName }}</p>
        <div class="d-flex my-2 overflow-auto align-items-center">
          <button
            class="btn-filled secondary-btn me-3 d-flex my-1 flex-shrink-0"
            type="button"
            @click="$router.back()"
          >
            <p>{{ $t("borrow.cancel") }}</p>
          </button>
          <button
            class="btn-filled primary-btn me-3 d-flex my-1 flex-shrink-0"
            @click.prevent="submitBorrowOrder"
          >
            <p>{{ $t("borrow.confirm_select") }}</p>
          </button>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center align-items-start">
          <div class="border-card col-12 col-lg-4">
            <p class="text-center mb-4">
              {{ $t("borrow.borrow_return_detail") }}
            </p>
            <div class="d-flex flex-column my-2">
              <p style="color: #7c7c7c;">
                {{ $t("borrow.borrow_start_date") }}*
              </p>
              <date-picker
                v-model="borrowStartDate"
                locale="en"
                mode="dateTime"
                is24hr
                :min-date="borrowStartDate"
                :masks="masks"
                :minute-increment="60"
                is-required
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    class="px-2 py-1 border rounded w-100 focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    readonly
                    @click="togglePopover()"
                  />
                </template>
              </date-picker>
            </div>
            <div class="d-flex flex-column my-2">
              <p style="color: #7c7c7c;">{{ $t("borrow.borrow_end_date") }}*</p>
              <date-picker
                v-model="borrowEndDate"
                locale="en"
                mode="dateTime"
                is24hr
                :min-date="borrowStartDate"
                :validHours="hourIsValidEnd"
                :masks="masks"
                :minute-increment="60"
                is-required
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    class="px-2 py-1 border rounded w-100 focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    readonly
                    @click="togglePopover()"
                  />
                </template>
              </date-picker>
            </div>
            <div class="my-2">
              <p style="color: #7c7c7c;">
                {{ $t("borrow.borrower") }}
              </p>
              <p>{{ $store.getters.getUserDetail.name }}</p>
            </div>
            <div class="my-2">
              <p style="color: #7c7c7c;">{{ $t("borrow.note") }}</p>
              <input v-model.trim="note" class="form-control" type="text" />
            </div>
            <div class="my-2">
              <p style="color: #7c7c7c;">Reference No.1</p>
              <input v-model.trim="refNo1" class="form-control" type="text" />
            </div>
            <div class="my-2">
              <p style="color: #7c7c7c;">Reference No.2</p>
              <input v-model.trim="refNo2" class="form-control" type="text" />
            </div>
          </div>
          <div class="border-card col-12 col-lg-4">
            <p class="text-center mb-4">{{ $t("borrow.borrow_list") }}</p>
            <!-- <p
              class="cursor-pointer d-inline-block my-2"
              style="color: #007AFE;"
              @click="$router.push({ name: 'select-borrow' })"
            >
              {{ $t("borrow.add_select_borrow") }}
            </p> -->
            <div class="d-flex justify-content-between my-2">
              <p style="color: #7c7c7c;">{{ $t("borrow.asset") }}</p>
              <p style="color: #7c7c7c;">
                {{ selectedAsset.length }} {{ $t("borrow.list") }}
              </p>
            </div>
            <asset-card
              :key="selectedAsset.id"
              action="edit"
              :assetId="selectedAsset.asset.id_asset"
              :assetName="selectedAsset.asset.name"
              :assetQR="selectedAsset.asset.id_fixed_asset"
              :assetImageUrl="selectedAsset.asset.image_url.url"
              @action-click="navigateToEditSelectBorrow"
            ></asset-card>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Loading from "vue-loading-overlay";

import AssetCard from "../../components/BorrowReturn/AssetCard.vue";
import { authHeader } from "../../store/actions";
import { borrowUrl } from "@/util/backend";
export default {
  components: {
    DatePicker,
    AssetCard,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      request_id: null,
      masks: {
        inputDateTime24hr: "DD/MM/YYYY HH:mm",
      },
      borrowStartDate: null,
      borrowEndDate: null,
      note: null,
      refNo1: null,
      refNo2: null,
      selectedAsset: {
        asset: {
          id_asset: -1,
          name: "",
          id_fixed_asset: "",
          image_url: {
            url: "",
          },
        },
        id: "",
      },
    };
  },
  watch: {
    borrowStartDate() {
      this.setBorrowDate();
    },
    borrowEndDate() {
      this.setBorrowDate();
    },
  },
  methods: {
    hourIsValidEnd(hour, dateParts) {
      if (moment(dateParts.date).isSame(this.borrowStartDate, "d")) {
        return hour > +moment(this.borrowStartDate).format("H");
      } else {
        return true;
      }
    },
    setBorrowDate() {
      this.$store.dispatch("borrow/setBorrowDate", {
        startDate: moment(this.borrowStartDate).format(),
        endDate: moment(this.borrowEndDate).format(),
      });
    },
    setSelectedAsset() {
      const selectedAssetMap = this.$store.getters["borrow/getBorrowAsset"];
      const selectedAssetValues = selectedAssetMap.values();
      const selectedAssetValue = selectedAssetValues.next().value;

      if (selectedAssetValue) {
        this.selectedAsset = {
          asset: {
            id_asset: selectedAssetValue.asset_id,
            image_url: {
              url: selectedAssetValue.asset_image,
            },
            id_fixed_asset: selectedAssetValue.id_fixed_asset,
            name: selectedAssetValue.name,
          },
        };
        return true;
      }
      return false;
    },
    navigateToEditSelectBorrow() {
      this.$router.push({
        name: "edit-order-select",
      });
    },
    async submitBorrowOrder() {
      const updatedAssets = this.selectedAsset;
      console.log(updatedAssets);

      try {
        await axios.post(
          `${borrowUrl()}asset/id/${this.borrow_return_asset_id}`,
          {
            start_datetime: this.borrowStartDate,
            end_datetime: this.borrowEndDate,
            borrow_note: this.note,
            ref_no_1: this.refNo1,
            ref_no_2: this.refNo2,
            asset_id: updatedAssets.asset.id_asset,
          },
          authHeader()
        );

        Swal.fire({
          icon: "success",
          title: this.$t("borrow.edit_borrow_success"),
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.$router.replace({ name: "manage-order" });
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: this.$t("borrow.edit_borrow_failed.title"),
          text: this.$t(`borrow.edit_borrow_failed.${err.response.data.error}`),
        });
      }
    },
    async fetchBorrowOrderByRequestId(borrow_return_asset_id) {
      try {
        const res = await axios(
          `${borrowUrl()}asset/id/${borrow_return_asset_id}`,
          authHeader()
        );
        const data = res.data;
        this.setBorrowOrderData(data);
      } catch (err) {
        console.error(err);
      }
      this.isLoading = false;
    },
    setBorrowOrderData(data) {
      this.borrowStartDate = data.start_datetime;
      this.borrowEndDate = data.end_datetime;
      this.note = data.borrow_note;
      this.refNo1 = data.ref_no_1;
      this.refNo2 = data.ref_no_2;

      // Set selected asset
      const selectedExistingAsset = this.setSelectedAsset();

      if (!selectedExistingAsset) {
        this.selectedAsset = {
          asset: {
            id_asset: data.asset.id_asset,
            name: data.asset.name,
            id_fixed_asset: data.asset.id_fixed_asset,
            image_url: {
              url: data.asset.image_url.url,
            },
          },
          id: data.id,
        };
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch("borrow/clearBorrowState");
  },
  created() {
    const { borrow_return_asset_id } = this.$route.params;

    // Check if borrow_return_asset_id is valid
    if (!borrow_return_asset_id) {
      return;
    } else if (isNaN(borrow_return_asset_id)) {
      return;
    }

    this.borrow_return_asset_id = borrow_return_asset_id;
    this.fetchBorrowOrderByRequestId(borrow_return_asset_id);
  },
};
</script>

<style lang="scss" scoped>
.btn-filled {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #fff;
    background-color: #007afe;
    border-color: #007afe;
  }
  &.secondary-btn {
    color: #fff;
    background-color: #818181;
    border-color: #818181;
  }
}

.border-card {
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  padding: 30px 50px;
  margin: 0 15px;
  p {
    margin: 0;
  }
}
</style>
